const HONOLULU_BLUE = "#1679BE";
const POPPY = "#da3540";
const NIGHT_BLACK = "#141414";
const ALICE_BLUE = "#e8f1f2";
const FROG_GREEN = "#3f0";

const out = {
  HONOLULU_BLUE,
  POPPY,
  NIGHT_BLACK,
  ALICE_BLUE,
  FROG_GREEN,
};

export default out;
